

<template>
  <div>
    <v-card flat style="background-color: var(--v-background-base) !important">
      <v-toolbar color="greyBase" dark extended flat extension-height="55">
        <v-toolbar-title>
          <b class="mr-3">Documentation Tools</b>
        </v-toolbar-title>
      </v-toolbar>

      <v-card class="mx-auto" :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'" :style="{
        'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '15px',
        'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
      }">
        <v-card-text :style="{
          height: !$vuetify.breakpoint.mobile ? '85vh' : '90vh',
          'overflow-y': 'auto',
        }">
          <v-tabs v-model="activeTab" height="30px">
            <!-- <v-tab href="#tempLogsAuditChart"> Containers Loaded</v-tab> -->
            <v-tab href="#documentLookup"> Documentation Lookup</v-tab>

          </v-tabs>

          <v-tabs-items v-model="activeTab" class="my-0 py-0"
            style="max-height: 100vh; overflow-y: auto; background: var(--v-component-base) !important; ">
            <!-- <v-tab-item value="tempLogsAuditChart">
                  <TempLogsAuditChart />
                </v-tab-item> -->
            <v-tab-item value="documentLookup">
              <DocumentLookupTool :item="item" @close="(documentLookupModal = false), (item = {})" style="height: 100%"/>
            </v-tab-item>

          </v-tabs-items>

        </v-card-text>

      </v-card>

    </v-card>
  </div>
</template>

<script>
import DocumentLookupTool from "../components/SystemComponents/DocumentLookupTool.vue";

export default {
  components: {
    DocumentLookupTool
  },
  data: () => ({
    activeTab: 'documentLookup',
    countries: [],
    country: null,
    containers: [],
    documentLookupModal: false,
    exportDocumentModal: false,
    exportItem: {},
    generatingDocument: false,
    item: {},
    loadingContainer: false,
    loadingModels: false,
    searchFunction: null,
    searchModel: null,
    searchContainer: null,
    selectedTool: null,
    sharedModels: [],
    snackbar: false,
    systemVariables: [],
    tools: [
      {
        id: 1,
        name: "Export Document Lookup",
        icon: "search",
        value: "exportSearch",
        function: "lookupDocument",
      },
      // { id: 2, name: "Test Template", icon: "article", value: "testTemplate" },
    ],
    timeout: null
  }),

  methods: {
    executeToolFunction(type) {
      switch (type) {
        case "lookupDocument":
          this.lookupDocument();
          break;
      }
    },
    async generateDocument() {
      this.generatingDocument = true
      let documentId = this.exportItem.locationDocumentId ? this.exportItem.locationDocumentId : this.exportItem.parentDocumentId

      let file = await this.$API.generateIntegrationDocument(documentId, this.exportItem.containerId);
      var hiddenElement = document.createElement("a");
      hiddenElement.href = file.file;
      hiddenElement.target = "_blank";
      hiddenElement.download = 'test.xlsx';
      hiddenElement.click();
      this.generatingDocument = false
    },
    lookupDocument() {
      this.item = {
        originCountry: this.country,
      };
      this.documentLookupModal = true;
    },


  }
}

</script>

<style></style>