var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticStyle:{"background-color":"var(--v-background-base) !important"},attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"color":"greyBase","dark":"","extended":"","flat":"","extension-height":"55"}},[_c('v-toolbar-title',[_c('b',{staticClass:"mr-3"},[_vm._v("Documentation Tools")])])],1),_c('v-card',{staticClass:"mx-auto",style:({
      'margin-top': !_vm.$vuetify.breakpoint.mobile ? '-64px' : '15px',
      'border-radius': !_vm.$vuetify.breakpoint.mobile ? '50px' : '0px',
    }),attrs:{"max-width":!_vm.$vuetify.breakpoint.mobile ? '94vw' : '100%'}},[_c('v-card-text',{style:({
        height: !_vm.$vuetify.breakpoint.mobile ? '85vh' : '90vh',
        'overflow-y': 'auto',
      })},[_c('v-tabs',{attrs:{"height":"30px"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',{attrs:{"href":"#documentLookup"}},[_vm._v(" Documentation Lookup")])],1),_c('v-tabs-items',{staticClass:"my-0 py-0",staticStyle:{"max-height":"100vh","overflow-y":"auto","background":"var(--v-component-base) !important"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab-item',{attrs:{"value":"documentLookup"}},[_c('DocumentLookupTool',{staticStyle:{"height":"100%"},attrs:{"item":_vm.item},on:{"close":function($event){(_vm.documentLookupModal = false), (_vm.item = {})}}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }